<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <store-form v-if="store"
                :submit-text="pageTitle"
                :store="store"
                @on-submit="createStore">
    </store-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import StoreForm from '@admin/views/store/_EditForm'
import StoreService from '@admin/services/StoreService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, StoreForm },
  data () {
    return {
      pageTitle: '新建店铺',
      store: {
        bsName: '',
        bsAddress: '',
        bsPosition: '',
        bsPhone: '',
        bsStatus: 1
      }
    }
  },
  async created () {
  },
  methods: {
    async createStore (store, success, callback) {
      const { data } = await flatry(StoreService.create(store))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/store/update', query: { id: data.data.id } })
        success()
      }

      callback()
    }
  }
}
</script>
